body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
[class~='ant-col'] {
  padding: 10px;
}
[class~='ant-row'] {
  padding-top: 70px;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
  
}
  [data-theme="dark"] .site-layout-content {
    background: #141414;
  }

  /* .site-card-border-less-wrapper {
    padding: 30px;
    background: #ececec;
  }
    [data-theme="dark"] .site-card-border-less-wrapper {
      background: #303030;
    } */